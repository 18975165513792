import React from 'react';
import {VideoWrap} from './video.style'

const Video = ({src, imgPoster, ...restProps}) => {
    return (
        <VideoWrap>
            <video muted={true} autoPlay={true} playsInline loop={true} poster="true">
                <source src={src} type="video/mp4" />
            </video>
        </VideoWrap>
    )
}

export default Video; 