import React from "react"
import { graphql } from "gatsby"
import Seo from '../components/seo'
import Layout from '../containers/layout/layout'
import Hero from '../containers/home-slider/hero'
import Story from '../containers/home-slider/story'
import ProjectSection from '../containers/home-slider/project'
import TestimonialSection from '../containers/home-slider/testimonial'
import ClientSection from '../containers/home-slider/clients'
import VideoSection from '../containers/home-slider/video'
import BlogSection from '../containers/home-slider/blog'

const Index = ({ data }) => {
    const { url, description, image } = data.site.siteMetadata;

    return (
        <Layout url={url}>
            <Seo title="Jardipiscines" description={description} image={image}/>
            <Hero/>
            <Story/>
            <ProjectSection/>
            <TestimonialSection/>
            <ClientSection/>
            <VideoSection/>
            <BlogSection/>
        </Layout>
    )
}

export default Index;

export const query = graphql`
query PageQuery {
  site {
    siteMetadata {
      siteUrl
      description
      image
    }
  }
}
`
