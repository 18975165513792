import React from 'react';
import PropTypes from 'prop-types'
import { Controller, Scene } from 'react-scrollmagic';
import {VideoSectionWrap} from './video.stc';
import Video from '../../../components/shared/video';
import VideoSrc from '../../../assets/video/film-photos-piscines.mp4';
import Poster from '../../../data/images/bck-fond-7.jpeg';

const VideoSection = () => {
    return (
        <VideoSectionWrap>
            <div className="col-4 offset-1">
                <div className="video-content">
                    <Controller>
                        <Scene classToggle="animated" triggerElement=".video-content" triggerHook="onCenter">
                            <div className="thumb rn_surface">
                            <Video src={VideoSrc} imgPoster={Poster}/>
                            </div>
                        </Scene>
                    </Controller>
                </div>
            </div>
        </VideoSectionWrap>
    )
}
  
VideoSection.propTypes = {
    section: PropTypes.object
}

VideoSection.defaultProps = {
    section: {
        backgroundColor: '#f8f8f8'
    },
}

export default VideoSection;